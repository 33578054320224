import React, {useState} from "react"
import { graphql } from 'gatsby';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../scss/bootstrap-overrides.scss"
import "../scss/global.scss";
import "../scss/grunticon.scss"; 
import "../scss/custom.scss";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import TeamDetails from "../components/TeamDetails/TeamDetails";

const TeamDetailsTemplate = ({data}) => {
    const GQLPage = data.glstrapi?.team;
    const [showMenu, setShowMenu] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
  

    const handlerClick = () => {
        var root = document.getElementsByTagName('html')[0];
        setShowMenu(!showMenu)
        if (showMenu == true) {
            document.body.className = '';
            root.className = '';
        } else {
            document.body.classList.add("overflow-hidden");
            root.classList.add("overflow-hidden");
        }
    }

    const handlerSearchClick = () => {
        setShowSearch(!showSearch)
        setShowMenu(false)
        if (showSearch == true) {
            document.body.className = '';

        } else {
            document.body.classList.add("overflow-hidden");
        }
    }

    return (
        <div className={` ${(showMenu || showSearch) ? "wrapper open-search-block" : "wrapper"}`} > 
            <Header showMenu={showMenu} handlerClick={handlerClick} handlerSearchClick={handlerSearchClick} showSearch={showSearch} fixed={true} /> 
            <TeamDetails team={GQLPage}  />
            {/* <Footer popularSearch={'Popular_Search_Home'} popularSearchType="static" /> */}
        </div>
    )
}

export default TeamDetailsTemplate;

export const pageQuery = graphql`
    query TeamQuery($id: ID!) {
        glstrapi {
            team(id: $id, publicationState: LIVE) {
                Name
                URL
                Designation
                offices{
                    Name
                }
                Meta_Title
                Meta_Description
                Full_Image {
                    url
                    alternativeText
                    formats
                }
                Email
                Phone
                Video_URL
                About
            }
        }
    }
`